import {api} from "../../../../api";
import {useEffect, useState} from "react";
import Skeleton from "react-loading-skeleton";
import "../styles/dealerPage.scss"
import SwitchButton from "../../components/SwitchButton";
import {useSelector} from "react-redux";
import {COUNTRY_CONFIG} from "../../../../utils/config";


const DealerPage = () => {
	const [dealerList, setDealerList] = useState(null)
	const [isLoading, setIsLoading] = useState(true)
	const [loadBtn, setLoadBtn] = useState(false)
	const [currentDealer, setCurrentDealer] = useState(null)

	const siteID = useSelector(state => state.userData.data.country).toLowerCase()

	//Получаем данные с сервера
	const getData = async () => {
		setIsLoading(true)
		try {
			const result = await api.cabinetApi.getDealerList();

			setDealerList(result.dealers)

			setIsLoading(false)
		} catch (err) {
			console.log(err)
		}
	}

	//Подлючаем дилера к системе
	const approveDealer = async (id) => {
		setLoadBtn(true)
		setCurrentDealer(id)
		try {
			const result = await api.cabinetApi.dealerApprove({dealer_id: id})
			if(result.status === true){
				getData()
			}
		} catch (err) {
			console.log(err)
		}finally {
			setLoadBtn(false)
			setCurrentDealer(null)
		}

	}

	//Функция подключения и отключения дилера
	const checkBoxHandler = async (data) => {
		try {
			const result = await api.cabinetApi.setDealerAccess({dealer_id: data.value, access: data.isChecked})

			//Обновляем состояние дилера в списке
			setDealerList((prev) =>{
				return prev.map((dealer) => {
					if(dealer.id === data.value){
						dealer.have_access = data.isChecked
					}
					return dealer
				})
			})

		} catch (err) {
			console.log(err)
		}
	}


	useEffect(() => {
		getData()
	}, [])

	return (
		<div className={`i_dealer-page`}>
			{isLoading && (
				<>
					<div style={{display: 'flex'}}>
						<Skeleton
							count={5}
							height={'9rem'}
							width={'100%'}
							inline={true}
							borderRadius={20}
							containerClassName={'i_dealer-page-skeleton'}
						/>
					</div>
				</>
			)}

			{!isLoading && (
				<div className="i_dealer-items">
					{dealerList.map((dealer, index) => {

						return (
							<div className={`i_dealer-item`} key={index}>
								<div className={`i_dealer-item-active`}>
									{dealer.connected_request ? <span className={'red'}>Заявка на подключение</span> :
										dealer.have_access ? <span className={'green'}>Доступ подключен</span> : <span className={'gray'}>Доступ отключен</span>
									}
								</div>
								{!dealer.connected_request ?
									<div className={'i_dealer-switch'}>
										<SwitchButton inputChecked={dealer.have_access} value={dealer.id} checkBoxValue={checkBoxHandler}/>
									</div>
								: ''}
								<div className="i_dealer-item-title">
									<span>{dealer.name}</span>
								</div>
								<div className="i_dealet-item-contact">
									<span>Контактное лицо: {dealer.contact}</span>
								</div>
								<div className="i_dealer-item-phone">
									<span>Телефон: {dealer.phone}</span>
								</div>
								<div className="i_dealer-item-web">
									<span>{COUNTRY_CONFIG[siteID].binName}: {dealer.bin}</span>
								</div>
								{
									dealer.connected_request ?
										<div className="i_dealer-item-request">
											<button className={`button-loader ${loadBtn && currentDealer === dealer.id ? 'loader' : ''}`} onClick={()=>{approveDealer(dealer.id)}}><span>Подключить</span></button>
										</div>
										:
										<div className="i_dealer-item-actives-stats">
											<div className="i_dealer-item-api-active">
												 <span>Активность по API: {dealer.statistic && dealer.statistic.api && dealer.statistic.api.connect_time
													 ? <span style={{'color' : '#01c716'}}>{dealer.statistic.api.connect_time}</span>
													 : 'Нет данных'}
		                                         </span>
											</div>
											<div className="i_dealer-item-cabinet-active">
			                                    <span>Активность в Кабинете: {dealer.statistic && dealer.statistic.cabinet && dealer.statistic.cabinet.connect_time
				                                    ? <span style={{'color' : '#01c716'}}>{dealer.statistic.cabinet.connect_time}</span>
				                                    : 'Нет данных'}
		                                         </span>
											</div>
										</div>
								}

							</div>
						);
					})}
				</div>

			)}
		</div>
	)
}

export default DealerPage;