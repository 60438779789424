import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {api} from "../../api";
import {useDispatch, useSelector} from "react-redux";
import ButtonLoader from "../../pages/main/components/ButtonLoader";
import CustomInput from "../../pages/main/components/CustomInput";
import CategoryList from "../../modal/components/CategoryList";
import Skeleton from "react-loading-skeleton";
import SelectInput from "../../pages/main/components/SelectInput";
import SwitchButton from "../../pages/main/components/SwitchButton";
import SelectDropdown from "../../pages/main/components/SelectDropdown";
import SelectButton from "../../pages/main/components/SelectButton";

const AddMarkupElement = () => {
	// Redux методы
	const dispatch = useDispatch();
	const catalogCode = useSelector(state => state.markups.catalogCode)
	const selectedProducts = useSelector(state => state.markups.selectedProduct)
	const selectedCategory = useSelector(state => state.markups.selectedCategory)

	//Состояния для инпутов
	const [name, setName] = useState('')
	const [active, setActive] = useState(true)
	const [selectPeriod, setSelectPeriod] = useState('unlimited')
	const [activeFrom, setActiveFrom] = useState(null)
	const [activeTo, setActiveTo] = useState(null)
	const [selectMarkupUnit, setSelectMarkupUnit] = useState('percent')
	const [markupValue, setMarkupValue] = useState(null)
	const [priorityValue, setPriorityValue] = useState('1')
	const [conditionValue, setConditionValue] = useState(null)

	//Состояния компоненты
	const [load, setLoad] = useState(false)
	const [error, setError] = useState('')
	const [loading, setLoading] = useState(false);
	//Нужно для перевода
	const {t} = useTranslation();

	// Меняем Show из switchCheckbox
	const checkboxHandler = (data) => {
		setActive(data.isChecked)
	}

	// Открытие модального окна выбора категорий и товаров\

	const openSelectCategoryAndProductModal = () => {
		dispatch({type: "SET_MARKUPS_CURRENT_CATEGORY_ID", currentCategoryId: 0})
		dispatch({type: "OPEN_MODAL", modalType: 'selectCategoryAndProductModal', modalLevel: 2, modalWidth: 'large'})
	}

	const periodData = [
		{
			name: "Неограниченно",
			value: 'unlimited'
		},
		{
			name: "Период",
			value: 'limited'
		}
	]

	const markupUnitData = [
		{
			name: "Процент",
			value: 'percent'
		},
		{
			name: "Точное значение",
			value: 'exact'
		}
	]

	const conditionsData = [
		{
			name: "Весь каталог",
			value: 'all'
		},
		{
			name: "Категории и товары",
			value: 'categoryAndProduct'
		}
	]


	const changeCurrentPeriod = ({value}) => {
		setSelectPeriod(value)
	}

	const formSubmit = async () => {
		//Отправляем запрос на добавление каталога
		setLoad(true)
		setError('')

		let body = {
			"catalog_code": catalogCode,
			"active": active,
			"name": name,
			"date_from": activeFrom,
			"date_to": activeTo,
			"markup": markupValue,
			"markup_unit": selectMarkupUnit,
			"priority": priorityValue,
			"condition": selectedProducts.length > 0 || selectedCategory.length > 0 ? {
				"categories" : selectedCategory,
				"products" : selectedProducts
			} : {"catalog_all":true}
		};

		// Делаем запрос
		try {
			const response = await api.cabinetApi.addMarkup(body);

			if(response.status === true){

			}else{
				setError(response.message)
			}

			console.log(response)

		} catch (error) {
			console.log(error);
		} finally {
			setTimeout(() => {
				setLoad(false)
			}, 700)
		}
	}

	return (
		<>
			{loading && (
				<div className={`i_add-category-modal-loading`} style={{height:"100%"}}>
					<Skeleton count={1} height={'100%'} borderRadius={10}/>
				</div>
			)}
			{
				!loading && (
					<div className={'i_add-category-modal'}>
						<div className="i_add-category-modal-title">
							<span>Добавление наценки</span>
						</div>
						<div className="i_add-category-content">
							<div className="i_add-category-items">

								<div className={`i_add-catalog-modal-item item-switch`}>
									<SwitchButton inputChecked={active} checkBoxValue={checkboxHandler} title={'Активно'} value={''}/>
								</div>

								<div className={`i_add-catalog-modal-item`}>
									<CustomInput title={'Название наценки'} value={name} onChange={(e) => {
										setName(e)
									}}/>
								</div>

								<div className={`i_add-catalog-modal-item`}>
									<SelectDropdown defaultValue={'Неограниченно'}
									                label={'Период активности'} item={periodData}
									                onChange={changeCurrentPeriod}/>
								</div>

								{
									selectPeriod && selectPeriod === 'limited' && (
										<>
											<div className={`i_add-catalog-modal-item`}>
												<CustomInput type={'date'} title={'Дата начала'} value={activeFrom}
												             onChange={(e) => {
													             setActiveFrom(e)
												             }}/>
											</div>
											<div className={`i_add-catalog-modal-item`}>
												<CustomInput type={'date'} title={'Дата окончания'} value={activeTo}
												             onChange={(e) => {
													             setActiveTo(e)
												             }}/>
											</div>
										</>
									)
								}

								<div className={`i_add-catalog-modal-item`}>
									<CustomInput title={'Размер наценки'} type={'number'} value={markupValue}
									             onChange={(e) => {
										             setMarkupValue(e)
									             }}/>
								</div>

								<div className={`i_add-catalog-modal-item`}>
									<SelectDropdown defaultValue={'Процент'} label={'Тип наценки'}
									                item={markupUnitData} onChange={({value}) => {
										setSelectMarkupUnit(value)
									}}/>
								</div>

								<div className={`i_add-catalog-modal-item`}>
									<CustomInput title={'Приоритет'} type={'number'} value={priorityValue}
									             onChange={(e) => {
										             setPriorityValue(e)
									             }}/>
								</div>

								<SelectDropdown defaultValue={'Весь каталог'} label={'Условие'}
								                item={conditionsData} onChange={({value}) => {
									setConditionValue(value)
								}}/>

								{ conditionValue === 'categoryAndProduct' &&
									<div className="i_add-catalog-modal-item">
										<SelectButton title={'Выбрать категории и товары'} onClick={openSelectCategoryAndProductModal}/>
									</div>
								}

							</div>

							<div className="i_add-category-modal-btn" onClick={formSubmit}>
								<ButtonLoader load={load} title={t('I_SELECT_ROLE_FORM_BTN')}/>
							</div>
							{error !== '' ?
								<div className={`i_add-category-modal-error`}>
									<span>{error}</span>
								</div> : ''
							}
						</div>
					</div>
				)
			}
		</>
	)
}

export default AddMarkupElement