import closeBtn from '../../images/closeModal.svg'
import {useDispatch, useSelector} from "react-redux";

import SelectRoleModalDealer from "../../modal/SelectRoleModalDealer";
import SelectRoleModalDistributor from "../../modal/SelectRoleModalDistributor";
import AddCatalogModal from "../../modal/AddCatalogModal";
import EditCatalogModal from "../../modal/EditCatalogModal";
import DistributorConnectModal from "../../modal/DistributorConnectModal";
import EditProfileModal from "../../modal/EditProfileModal";
import AddPriceTypeModal from "../../modal/AddPriceTypeModal";
import EditPriceTypeModal from "../../modal/EditPriceTypeModal";
import DistributorCatalogProductDetailModal from "../../modal/DistributorCatalogProductDetailModal";
import DealerCatalogProductDetailModal from "../../modal/DealerCatalogProductDetailModal";
import MyCatalogProductDetailModal from "../../modal/MyCatalogProductDetailModal";
import AddSourceCatalogModal from "../../modal/AddSourceCatalogModal";
import EditSourceCatalogModal from "../../modal/EditSourceCatalogModal";
import AddCategoryModal from "../../modal/AddCategoryModal";
import EditCategoryModal from "../../modal/EditCategoryModal";
import SourceCatalogProductDetailModal from "../../modal/SourceCatalogProductDetailModal";
import CategoryListMobileModal from "../../modal/CategoryListMobileModal";
import EditProductImagesModal from "../../modal/EditProductImagesModal";
import SourceCategoryListMobileModal from "../../modal/SourceCategoryListMobileModal";
import AttachCategoryModal from "../../modal/AttachCategoryModal";
import AddElementModal from "../../modal/AddElementModal";
import SelectCategoryAndProductModal from "../../modal/SelectCategoryAndProductModal";



const MultiModal = () => {

	const dispatch = useDispatch();
	const modals = useSelector(state => state.multiModal.modals);

	const closeModal = (modalLevel) =>{
		dispatch({type: "CLOSE_MODAL", modalLevel: modalLevel})
		dispatch({type: "CLEAR_UPLOADED_IMAGES"})
	}

	return (
		modals.map((modal, index) => {
			return (
				<div
					className={`i_multi_modal ${!modal.modalIsOpen ? 'hide' : ''} ${modal.modalWidth ? modal.modalWidth : ''} modal-level-${modal.modalLevel}`}
					key={index}
					style={modal.modalHeight === 'full' ? {height: '100%'} : {}}
				>
					<div className="i_container">
						<div className={`i_multi_modal-close`} onClick={() => {
							closeModal(modal.modalLevel)
						}}>
							<img src={`${closeBtn}`} alt="close"/>
						</div>

						{/*В зависимоти от переданного значения будет открываться определенное модальное окно*/}
						{modal.modalType === 'selectRoleModalDealer' && <SelectRoleModalDealer/>}
						{modal.modalType === 'selectRoleModalDistributor' && <SelectRoleModalDistributor/>}
						{modal.modalType === 'addCatalogModal' && <AddCatalogModal/>}
						{modal.modalType === 'editCatalogModal' && <EditCatalogModal/>}
						{modal.modalType === 'distributorConnectModal' && <DistributorConnectModal/>}
						{modal.modalType === 'editProfileModal' && <EditProfileModal/>}
						{modal.modalType === 'addPriceTypeModal' && <AddPriceTypeModal/>}
						{modal.modalType === 'editPriceTypeModal' && <EditPriceTypeModal/>}
						{modal.modalType === 'distributorCatalogProductDetailModal' && <DistributorCatalogProductDetailModal/>}
						{modal.modalType === 'dealerCatalogProductDetailModal' && <DealerCatalogProductDetailModal/>}
						{modal.modalType === 'myCatalogProductDetailModal' && <MyCatalogProductDetailModal/>}
						{modal.modalType === 'sourceCatalogProductDetailModal' && <SourceCatalogProductDetailModal/>}
						{modal.modalType === 'addSourceCatalogModal' && <AddSourceCatalogModal/>}
						{modal.modalType === 'editSourceCatalogModal' && <EditSourceCatalogModal/>}
						{modal.modalType === 'addCategoryModal' && <AddCategoryModal/>}
						{modal.modalType === 'editCategoryModal' && <EditCategoryModal/>}
						{modal.modalType === 'categoryListMobileModal' && <CategoryListMobileModal/>}
						{modal.modalType === 'sourceCategoryListMobileModal' && <SourceCategoryListMobileModal/>}
						{modal.modalType === 'editProductImagesModal' && <EditProductImagesModal/>}
						{modal.modalType === 'attachCategoryModal' && <AttachCategoryModal />}
						{modal.modalType === 'addElementModal' && <AddElementModal />}
						{modal.modalType === 'selectCategoryAndProductModal' && <SelectCategoryAndProductModal />}
					</div>
				</div>
			)
		})
	);
}

export default MultiModal;