import {useSelector, useDispatch} from "react-redux";
import {api} from "../../../../../api";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import infoImg from "../../../../../images/info.svg";
import SelectInput from "../../SelectInput";

const DistributorProductList = () => {
	// Инициализация dispatch для отправки экшенов в Redux
	const dispatch = useDispatch();

	// Получение необходимых данных из Redux store
	const nextOffset = useSelector(state => state.catalog.productList.nextOffset);
	const currentCategoryId = useSelector(state => state.catalog.productList.currentCategoryId);
	const checkedProductList = useSelector(state => state.catalog.productList.checkedProductList);
	const productList = useSelector(state => state.catalog.productList.productList);

	// Получение параметра `code` из URL через useParams
	let {distributor_id, code} = useParams();

	// Локальные состояния для отображения кнопки "Ещё", отметки всех товаров и списка товаров
	const [showMore, setShowMore] = useState(true);
	const [checkedAllProduct, setCheckedAllProduct] = useState(false);
	const [product, setProduct] = useState([]);

	// Следим за изменением checkedProductList и сбрасываем флаг checkedAllProduct
	useEffect(()=>{
		if(checkedProductList?.length <= 0){
			setCheckedAllProduct(false)
		}
	},[checkedProductList])

	// Обновляем локальный список товаров, когда изменяется productList из Redux
	useEffect(() => {
		setProduct(productList)
	}, [productList]);

	// При изменении текущей категории сбрасываем все выделенные товары
	useEffect(() => {
		setCheckedAllProduct(false)
		dispatch({type: "SET_CHECKED_PRODUCT_LIST", checkedProductList: []})
	}, [currentCategoryId]);

	// Обработчик для изменения состояний всех чекбоксов
	const checkAllCheckBox = () => {
		setCheckedAllProduct(!checkedAllProduct);
		const updatedSelectedProduct = [...product].map(productItem => {
			productItem.checked = !checkedAllProduct;
			return productItem;
		});
		setProduct(updatedSelectedProduct);

		// Обновляем selectedProduct с товарами, которые отмечены
		const selectedItems = updatedSelectedProduct
			.filter(productItem => productItem.checked)
			.map(productItem => productItem.id);
		dispatch({type: "SET_CHECKED_PRODUCT_LIST", checkedProductList: selectedItems})
	};

	// Обработчик изменения состояния отдельного чекбокса
	const checkBoxHandler = ({ value }) => {
		const updatedSelectedProduct = product.map(productItem => {
			if (productItem.id === value) {
				productItem.checked = !productItem.checked;
			}
			return productItem;
		});
		setProduct(updatedSelectedProduct);

		// Обновляем selectedProduct
		const updatedSelectedIds = updatedSelectedProduct
			.filter(productItem => productItem.checked)
			.map(productItem => productItem.id);
		dispatch({type: "SET_CHECKED_PRODUCT_LIST", checkedProductList: updatedSelectedIds})
	};

	// Следим за изменением nextOffset и контролируем отображение кнопки "Ещё"
	useEffect(() => {
		if (nextOffset === null || nextOffset === 'undefined') {
			setShowMore(false)
		}else if(nextOffset >= 100){
			setShowMore(true)
		}
	}, [nextOffset])

	//Функция для открытия модального окна с информацией о товаре
	const openCatalogDetailModal = (id) => {
		dispatch({type: "OPEN_MODAL", modalType: "distributorCatalogProductDetailModal", modalLevel: 1, modalData: {productId : id}, modalWidth: 'large'})
	}

	//Функция для подгрузки товаров
	const loadMore = async () => {
		try {
			let productList = '';

			if (distributor_id) {
				productList = await api.cabinetApi.getDistributorCatalogProductList({
					code: code,
					distributor_id: distributor_id,
					offset: nextOffset,
					filter: {"category_id": currentCategoryId}
				});
			} else {
				productList = await api.cabinetApi.getCatalogProductList({
					code: code,
					offset: nextOffset,
					filter: {"category_id": currentCategoryId}
				});
			}

			dispatch({
				type: "GET_MORE_PRODUCT_LIST",
				productList: productList.products,
				offset: productList.offset,
				nextOffset: productList.next_offset
			})
			if (!productList.next_offset) {
				setShowMore(false)
			}
		} catch (e) {
			console.log(e)
		}
	}

	const role = useSelector(state => state.userData.data.entity.type)


	return (
		<>
			<div className="i_catalog-product-head source-product">
				<div className="i_catalog-product-head-item checkbox">
					<SelectInput title="" value={0} checkBoxValue={checkAllCheckBox} inputChecked={checkedAllProduct}
					             onlyOne={true}/>
				</div>
				<div className="i_catalog-product-head-item products">
					<span>Товары</span>
				</div>
				<div className="i_catalog-product-head-item active">
					<span>Активн.</span>
				</div>
				<div className="i_catalog-product-head-item name">
					<span>Название</span>
				</div>
				<div className="i_catalog-product-head-item article">
					<span>Артикул</span>
				</div>
				<div className="i_catalog-product-head-item update">
					<span>Дата обновления</span>
				</div>
				{
					!distributor_id && (
						<div className="i_catalog-product-head-item ext_id">
							<span>Внешний код</span>
						</div>
					)
				}
				<div className="i_catalog-product-head-item inner_code">
					<span>Внутренний код</span>
				</div>
				<div className="i_catalog-product-head-item quantity">
					<span>Остаток</span>
				</div>
				<div className="i_catalog-product-head-item price">
					<span>Цена Дил.</span>
				</div>
				<div className="i_catalog-product-head-item rrp">
					<span>RRP</span>
				</div>
			</div>
			<div className="i_catalog-product-items source-product">
				{!distributor_id && productList.length === 0 && (
					<div className="i_catalog-product-empty">
						<div className="i_catalog-page-empty-title">
							<img src={infoImg} alt=""/>
							<span>Товары отсутствуют.</span>
						</div>
						<span>Вы можете добавить товары через&nbsp;
							{
								role && role === 'dealer' ?
									<>
										<a href="https://docs.apicore.kz/dealer-api/" target="_blank">Dealer API</a>
									</>
									: <a href="https://docs.apicore.kz/distributor-api/" target="_blank">Distributor API</a>
							}

						</span>
					</div>
				)}
				{
					productList.length > 0 ? productList.map((product, index) => {
						return (
							<div className={`i_catalog-product-item source-product`} key={product.id} onClick={() => {
								openCatalogDetailModal(product.id)
							}}>
								<div className="i_catalog-product-item-checkbox" onClick={e => e.stopPropagation()}>
									<SelectInput title="" value={product.id} checkBoxValue={checkBoxHandler}
									             inputChecked={product.checked} key={index}/>
								</div>
								<div className="i_catalog-product-item-active">
									<div className={`item-circle ${product.active ? 'active' : ''}`}></div>
								</div>
								<div className="i_catalog-product-item-name">
									<span>{product.name}</span>
								</div>
								<div className="i_catalog-product-item-article">
									<span>{product.vendor_code}</span>
								</div>
								<div className="i_catalog-product-item-update">
									<span>{product.date_update}</span>
								</div>
								{
									!distributor_id && (
										<div className="i_catalog-product-item-ext_id">
											<span>{product.ext_id}</span>
										</div>
									)
								}
								<div className="i_catalog-product-item-inner_code">
									<span>{product.inner_code}</span>
								</div>
								<div className="i_catalog-product-item-quantity">
									<span className={'i_catalog-product-item-title'}>Остаток: </span>
									<span>{product.quantity}</span>
								</div>
								<div className="i_catalog-product-item-price">
									<span className={'i_catalog-product-item-title'}>Цена: </span>
									<span>{product.prices && "2" in product.prices ? product.prices["2"].print_price : 'Нет цены'}</span>
								</div>
								<div className="i_catalog-product-item-rrp">
									<span>{product.prices && "1" in product.prices ? product.prices["1"].print_price : 'Нет цены'}</span>
								</div>
							</div>
						);
					}) : ''
				}
				{showMore && (
					<div className={'i_catalog-product-more'}>
						<span onClick={() => {
							loadMore()
						}}>Ещё</span>
					</div>
				)}
			</div>

		</>
	)
}

export default DistributorProductList;