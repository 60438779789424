import {useSelector, useDispatch} from "react-redux";
import {api} from "../../../../../api";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import infoImg from "../../../../../images/info.svg";

const MyProductList = ({markupList}) => {
	const dispatch = useDispatch();
	const nextOffset = useSelector(state => state.catalog.productList.nextOffset);
	const currentCategoryId = useSelector(state => state.catalog.productList.currentCategoryId);
	let { code} = useParams();
	const [showMore, setShowMore] = useState(true);

	//Функция для открытия модального окна с информацией о товаре
	const openCatalogDetailModal = (id) => {
		dispatch({type: "OPEN_MODAL", modalLevel: 1, modalType: "myCatalogProductDetailModal", modalData: {productId : id}, modalWidth: 'large'})
	}


	return (
		<>
			<div className="i_catalog-product-head">
				<div className="i_catalog-product-head-item active">
					<span>Активн.</span>
				</div>
				<div className="i_catalog-product-head-item name">
					<span>Название</span>
				</div>
				<div className="i_catalog-product-head-item update">
					<span>Период активности</span>
				</div>
				<div className="i_catalog-product-head-item markups-size">
					<span>Размер наценки</span>
				</div>
				<div className="i_catalog-product-head-item priority">
					<span>Приоритет</span>
				</div>
				<div className="i_catalog-product-head-item conditions">
					<span>Условия</span>
				</div>
			</div>
			<div className="i_catalog-product-items">
				{markupList.length === 0 && (
					<div className="i_catalog-product-empty">
						<div className="i_catalog-page-empty-title">
							<img src={infoImg} alt=""/>
							<span>Наценки отсутствуют.</span>
						</div>
					</div>
				)}
				{
					markupList.length > 0 ? markupList.map((markup, index) => {
						return (
							<div className={`i_catalog-product-item`} key={markup.id}>
								<div className="i_catalog-product-item-active">
									<div className={`item-circle ${markup.active ? 'active' : ''}`}></div>
								</div>
								<div className="i_catalog-product-item-name">
									<span>{markup.name}</span>
								</div>
								<div className="i_catalog-product-item-update">
									<span>{markup.date_from && markup.date_to ? `${markup.date_from} - ${markup.date_to}` : 'Неограниченно'}</span>
								</div>
								<div className="i_catalog-product-item-markups-size">
									<span>{markup.markup}{markup.markup_unit}</span>
								</div>
								<div className="i_catalog-product-item-markups-priority">
									<span>{markup.priority}</span>
								</div>
								<div className="i_catalog-product-item-markups-conditions">
									<span>{markup.condition.catalog_all}</span>
								</div>
							</div>
						);
					}) : ''
				}
			</div>

		</>
	)
}

export default MyProductList;