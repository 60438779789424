import {ReactSVG} from "react-svg";
import edit from '../../../images/catalogs/category/edit.svg'
import {useDispatch, useSelector} from "react-redux";

const EditCategory = () => {

	const dispatch = useDispatch();
	const currentCategoryId = useSelector(state => state.catalog.categoryList.currentCategoryId)

	return(
		<div className={`i_catalogtools-item ${!currentCategoryId ? 'disabled' : ''}`}
		     onMouseEnter={() => dispatch({type: "SWITCH_CURSOR_HELP", show: true, content: 'Изменить категорию'})}
		     onMouseLeave={() => dispatch({type: "SWITCH_CURSOR_HELP", show: false})}
		     onClick={() => dispatch({type: "OPEN_MODAL", modalType: 'editCategoryModal', modalLevel: 1})}
		>
			<ReactSVG src={`${edit}`} className={'i_catalogtools-item-svg'}/>
		</div>
	)
}

export default EditCategory;