import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {api} from "../../api";
import {useDispatch, useSelector} from "react-redux";
import ButtonLoader from "../../pages/main/components/ButtonLoader";
import CustomInput from "../../pages/main/components/CustomInput";
import CategoryList from "../../modal/components/CategoryList";
import Skeleton from "react-loading-skeleton";

const AttachSourceCategory = () => {
	// Redux методы
	const dispatch = useDispatch();
	const catalogCode = useSelector(state => state.catalog.catalogCode)
	const currentCatalogItem = useSelector(state => state.multiModal.modals[0].currentCategoryId)
	const checkedProductList = useSelector(state => state.catalog.productList.checkedProductList);
	const currentCategoryId = useSelector(state => state.catalog.productList.currentCategoryId);

	//Состояния для инпутов
	const [name, setName] = useState('')

	//Состояния компоненты
	const [load, setLoad] = useState(false)
	const [error, setError] = useState('')
	const [loading, setLoading] = useState(true);

	//Нужно для перевода
	const {t} = useTranslation();

	// Получаем список категорий
	const getList = async () => {
		try {
			const categoryList = await api.cabinetApi.getSourceCategoryList({source_code: catalogCode});

			if(categoryList.status === true){
				dispatch({
					type: "SET_MODAL_DATA",
					modalLevel: 1,
					modalData: {categoryList: categoryList.categories, currentCategoryId: null}
				})
				setLoading(false)
			}
		} catch (e) {
			console.log(e)
		}
	}

	useEffect(() => {
		getList()
	}, []);


	//Массив с инпутами
	const formItems =
		[
			{
				name: 'Название категории',
				value: name,
				stateFunc: setName
			}
		]

	const formSubmit = async () => {
		//Отправляем запрос на добавление каталога
		setLoad(true)
		setError('')

		//Создаем тело запроса
		let body = {
			"id": checkedProductList, // массив ID товаров
			"category_id":currentCatalogItem
		};

		// Делаем запрос
		try {
			const result = await api.cabinetApi.setSourceProductCategoryAttach(body);

			if (result.status === true) {
				let body = {
					"source_code": catalogCode,
					"offset": 0,
					"filter": {"category_id": currentCategoryId}
				}

				const productList = await api.cabinetApi.getSourceProductList(body);
				if (!productList.next_offset) {
					dispatch({
						type: "GET_PRODUCT_LIST",
						productList: productList.products,
						offset: productList.offset,
						nextOffset: null,
						currentCategoryId: currentCategoryId
					})
				} else {
					dispatch({
						type: "GET_PRODUCT_LIST",
						productList: productList.products,
						offset: productList.offset,
						nextOffset: productList.next_offset,
						currentCategoryId: currentCategoryId
					})
				}

				dispatch({type: "CLOSE_MODAL", modalLevel: 1})
				dispatch({type: "SET_CHECKED_PRODUCT_LIST", checkedProductList: []})
				dispatch({type: "SHOW_NOTIFICATION", notificationIsOpen: true, notificationContent: result.message, notificationType: 'success'})
			} else {
				setError(result.message)
			}

		} catch (error) {
			console.log(error);
		} finally {
			setTimeout(() => {
				setLoad(false)
			}, 700)

		}
	}

	return (
		<>
			{loading && (
				<div className={`i_add-category-modal-loading`} style={{height:"100%"}}>
					<Skeleton count={1} height={'100%'} borderRadius={10}/>
				</div>
			)}
			{
				!loading && (
					<div className={'i_add-category-modal'}>
						<div className="i_add-category-modal-title">
							<span>Привязка товаров к категории</span>
						</div>
						<div className="i_add-category-content">
							<div className="i_add-category-list open">
								<div className="i_add-category-list-title">
									<span>Структура категорий</span>
								</div>
								<div className="i_add-category-list-content">
									<CategoryList/>
								</div>
							</div>
							<div className="i_add-category-modal-btn" onClick={formSubmit}>
								<ButtonLoader load={load} title={"Привязать"}/>
							</div>
							{error !== '' ?
								<div className={`i_add-category-modal-error`}>
									<span>{error}</span>
								</div> : ''
							}
						</div>
					</div>
				)
			}
		</>
	)
}

export default AttachSourceCategory;