import {ReactSVG} from "react-svg";
import edit from '../../../images/catalogs/attach.svg'
import {useDispatch, useSelector} from "react-redux";

const AttachCategory = () => {

	const dispatch = useDispatch();
	const checkedProductList = useSelector(state => state.catalog.productList.checkedProductList);

	return (
		<div>
			<div className={`i_multitools-item attach-item ${checkedProductList?.length <= 0 ? 'disabled' : ''}`}
			     onMouseEnter={() => dispatch({type: "SWITCH_CURSOR_HELP", show: true, content: 'Привязать товары к категории'})}
			     onMouseLeave={() => dispatch({type: "SWITCH_CURSOR_HELP", show: false})}
			     onClick={() => checkedProductList?.length > 0 ? dispatch({type: "OPEN_MODAL", modalType: 'attachCategoryModal', modalLevel: 1}) : ''}
			>
				<ReactSVG src={`${edit}`} className={'i_multitools-item-svg'}/>
			</div>
		</div>
	)
}

export default AttachCategory;