import Skeleton from "react-loading-skeleton";
import {api} from "../../../../api";
import {useEffect, useState} from "react";
import DistributorProductList from "../../components/distributor/catalogPage/DistributorProductList";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import "../styles/catalogPage.scss"
import infoImg from "../../../../images/info.svg";
import OpenCatalogs from "../../components/OpenCatalogs";
import CloseCatalogs from "../../components/CloseCatalogs";
import CategoryListNew from "../../components/CategoryListNew";
import CatalogTools from "../../../components/CatalogTools";
import CategoryListMobile from "../../components/CategoryListMobile";

const CurrentCatalogPage = () =>{
	const [isLoading, setIsLoading] = useState(true)
	const dispatch = useDispatch();

	let { code } = useParams();

	//Достаем список категорий и товаров
	const getList = async () => {
		try {
			const categoryList = await api.cabinetApi.getCatalogCategoryList({code: code});
			const productList = await api.cabinetApi.getCatalogProductList({code: code , offset:0});

			//Записываем в стейт список товаров
			dispatch({type: "GET_PRODUCT_LIST", productList: productList.products, offset: productList.offset, nextOffset: productList.next_offset || null})
			dispatch({type: "SET_CATALOG_CATEGORY_LIST", categoryList: categoryList.categories})

			setIsLoading(false)
		} catch (err) {
			console.log(err)
		}
	}

	useEffect(() => {
		// Устанавливаем текущий мультитул
		setTimeout(()=>{
			dispatch({type: "SET_TOOLS", currentTools: "distributor-catalogs-inner"})
		},5)
		dispatch({type: "SET_CATALOG_CODE", catalogCode: code})

		// Получаем список категорий и товаров
		getList()
	}, [])

	//Достаем список товаров из стейта
	const productListState = useSelector(state => state.catalog.productList.productList);
	const categoryList = useSelector(state => state.catalog.categoryList.categoryList);

	return(
		<div className={`i_catalog-page`}>
			{isLoading && (
				<>
					<div style={{display: 'flex', height:"100%"}}>
						<Skeleton
							count={2}
							height={'100%'}
							inline={true}
							borderRadius={20}
							containerClassName={'i_catalog-page-in-skeleton'}
						/>
					</div>
				</>
			)}
			{!isLoading && categoryList && productListState &&(
				<div className={`i_catalog-main`}>
					{/*Выводим список категорий с вложенностями*/}
					<div className="i_catalog-category">
						<div className="i_catalog-tools">
							<CatalogTools/>
							<div className="i_catalog-tools-right">
								<OpenCatalogs/>
								<CloseCatalogs/>
							</div>
						</div>
						<div className="i_catalog-category-items">
							{
								<CategoryListNew />
							}
							{
								categoryList.length === 0 && (
									<div className="i_catalog-category-empty">
										<div className="i_catalog-page-empty-title">
											<img src={infoImg} alt=""/>
											<span>Категории отсутствуют.</span>
										</div>
										<span>Вы можете добавить категории через&nbsp;
											<a href="https://docs.apicore.kz/distributor-api/"
											   target="_blank">Distributor API</a>
										</span>
									</div>
								)
							}
						</div>
					</div>

					{/*Мобильная версия категорий*/}
					<CategoryListMobile />

					{/*Выводим список товаров*/}
					<div className="i_catalog-product">
						<DistributorProductList productList={productListState} />
					</div>
				</div>

			)}
		</div>
	)
}

export default CurrentCatalogPage;