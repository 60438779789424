import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import minus from "../../images/catalogs/category/minus.svg";
import plus from "../../images/catalogs/category/plus.svg";
import {api} from "../../api";

// Рекурсивный компонент для отображения категорий
const Category = ({category, categories, store}) => {
	// Находим подкатегории для текущей категории
	const subCategories = categories.filter(subCategory => subCategory.parent_id === category.id);
	const dispatch = useDispatch();
	const level = category.depth_level;

	const currentCatalogItem = store?.currentCategoryId;
	const catalogCode = store?.catalogCode;

	const changeCurrentCategory = (item) => {
		if (item.id === currentCatalogItem) {
			dispatch({type: 'SET_MARKUPS_CURRENT_CATEGORY_ID', currentCategoryId: null});
		} else {
			dispatch({type: 'SET_MARKUPS_CURRENT_CATEGORY_ID', currentCategoryId: item.id});
		}
	};

	async function changeSelectedItem(id) {
		let updatedItem = categories.map((item) => {
			if (item.id === id) {
				item.is_active = true;
				changeCurrentCategory(item)
			} else {
				item.is_active = false;
			}

			return item
		})

		let body = {
			"code": catalogCode,
			"offset": 0,
			"filter": {"category_id": id}
		}

		const updatedProductList = await api.cabinetApi.getCatalogProductList(body);

		dispatch({type: "SET_MARKUPS_CATEGORY", categoryList: updatedItem});
		dispatch({type: "SET_MARKUPS_PRODUCT", productList: updatedProductList.products});
		dispatch({type: "SET_NEXT_OFFSET", nextOffset: !updatedProductList.next_offset ? null : updatedProductList.next_offset});
	}

	function changeIsShow(id){
		let updatedItem = categories.map((item) => {
			if(item.id === id && item.has_children){
				item.is_show = !item.is_show
			}else{
				return item
			}
			return item
		})

		dispatch({type: "SET_MARKUPS_CATEGORY", categoryList: updatedItem});
	}

	function handleEditSelectedItem(event, id){
		event.stopPropagation();

		const updatedItem = categories.map((item) => {
			if(item.id === id){
				item.flag_select = !item.flag_select
			}

			return item
		})

		const selectedIds = updatedItem
			.filter(item => item.flag_select)   // выбираем только элементы с flag_select === true
			.map(item => item.id);              // извлекаем их id

		dispatch({type: "SET_MARKUPS_SELECTED_CATEGORY", selectedCategory: selectedIds});
		dispatch({type: "SET_MARKUPS_CATEGORY", categoryList: updatedItem});
	}


	return (
		<div
			className={`i_catalog-category-item ${category.is_show ? 'active' : ''} i_catalog-category-item-level-${level} ${!category.has_children ? 'last-child' : ''}`}
			style={{paddingLeft: level === 0 ? '0' : '20px'}}>
			<div className={`i_catalog-category-item-title ${currentCatalogItem === category.id ? 'selected' : ''}`}
			     onClick={() => {
				     changeSelectedItem(category.id)
			     }}
			>
				{
					category.id !== 0 && (
						<>
							<div
								className={`i_catalog-title-circle ${!category.has_children ? '' : 'last-child'} ${currentCatalogItem === category.id ? 'active' : category.is_active ? 'active' : ''}`}
								onClick={(e) => {
									e.stopPropagation();
									category.has_children && changeIsShow(category.id)
								}}
							>
								{category.is_show && category.has_children ? <img src={minus} alt="-"/> : (!category.has_children ? null : <img src={plus} alt="+"/>)}
							</div>

							<div className={`i_catalog-category-item-select ${category?.flag_select ? 'selected' : ''}`} onClick={(event)=>{handleEditSelectedItem(event, category.id)}}>
								<span>{category?.flag_select ? 'выбрано' : 'выбрать'}</span>
							</div>
						</>

					)
				}
				{category.name}
			</div>
			{
				category.id !== 0 && (
					subCategories.map(subCategory => (
						<Category key={subCategory.id} category={subCategory} categories={categories} store={store}/>
					))
				)
			}
		</div>
	);
};

const SelectCategoryList = ({store}) => {
	// Находим корневые категории (parent_id === 0)
	const categories = store.categoryList;
	if (!categories) return null;

	const rootCategories = categories?.filter(category => category.parent_id === 0 || category.id === 0);

	return (
		<div className={'i_catalog-category-items'}>
			{rootCategories.map(category => (
				<Category key={category.id} category={category} categories={categories} store={store}/>
			))}
		</div>
	);
};

export default SelectCategoryList;


