import AddCategory from "../multitools/dealer/catalogPage/AddCategory";
import EditCategory from "../multitools/dealer/catalogPage/EditCategory";
import DeleteCategory from "../multitools/dealer/catalogPage/DeleteCategory";
import EditSourceCategory from "../multitools/components/EditSourceCategory";
import EditMyCategory from "../multitools/components/EditMyCategory";
import AttachCategory from "../multitools/dealer/catalogPage/AttachCategory";
import AddElement from "../multitools/dealer/catalogPage/AddElement";
import DeleteElement from "../multitools/dealer/catalogPage/DeleteElement";

const currentDomain = window.location.hostname;
const domainSegments = currentDomain.split('.');
const lastSegment = domainSegments.pop();

export const API_CABINET_URL = `${lastSegment === 'd' ? 'http' : 'https'}://api.apicore.${lastSegment}/cabinet`
export const API_URL = `${lastSegment === 'd' ? 'http' : 'https'}://api.apicore.${lastSegment}`

// Конфигурация для мульти-тулзов

export const TOOLS_CONFIG = {
	"pages" : {
		"catalogs-my-inner" : {
			"catalogTools" : {
				"components" : [AddCategory, EditCategory,DeleteCategory],
			},
			"multiTools" : {
				"components" : [],
			},
			"elementTools" : {
				"components" : [AddElement, DeleteElement],
			}
		},
		"catalogs-distributor-inner":{
			"catalogTools" : {
				"components" : [],
			},
			"multiTools" : {
				"components" : [],
			},
			"elementTools" : {
				"components" : [],
			}
		},
		"catalogs-source-inner"  : {
			"catalogTools" : {
				"components" : [AddCategory, EditCategory,DeleteCategory],
			},
			"multiTools" : {
				"components" : [AttachCategory],
			},
			"elementTools" : {
				"components" : [],
			}
		},
		"distributor-catalogs-inner" : {
			"catalogTools" : {
				"components" : [AddCategory, EditCategory, DeleteCategory],
			},
			"multiTools" : {
				"components" : [AttachCategory],
			},
			"elementTools" : {
				"components" : [],
			}
		},
		"markups-my-catalog": {
			"catalogTools" : {
				"components" : [],
			},
			"multiTools" : {
				"components" : [],
			},
			"elementTools" : {
				"components" : [AddElement, DeleteElement],
			}
		}
	}
}


// Конфигурация для стран
export const COUNTRY_CONFIG = {
	'kz': {phone: '+7 (999)-999-99-99', bin: '999999999999', binName: 'БИН'},
	'uz': {phone: '+\\9\\98 (99)-999-99-99', bin: '999999999', binName: 'ИНН'},
	'kg': {phone: '+\\9\\96 (999)-999-999', bin: '99999999999999', binName: 'БИН'},
}




