import {useEffect, useState} from "react";

import Home from "./pages/home";
import Index from "./pages/auth";
import MultiModal from "./pages/components/MultiModal";
import Overlay from "./pages/components/Overlay";
import Loader from "./pages/components/Loader";

import './styles/normalize.css'
import './styles/style.scss'

import useLocalStorage from "./hooks/useLocalStorage";
import getTopLevelDomain from "./utils/getTopLevelDomain";

import {api} from "./api";
import {useDispatch, useSelector} from "react-redux";

import MultiPopup from "./pages/components/MultiPopup";
import MultiHelp from "./pages/components/MultiHelp";
import TabBar from "./pages/main/components/TabBar";


function App() {

	// const [userData, setUserData] = useState(null);
	const [authorized, setAuthorized] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const dispatch = useDispatch()
	const topLevelDomain = getTopLevelDomain()

	// Хук для сохранения языка в localStorage
	const [language, setLanguage] = useLocalStorage('language', 'ru')
	// Если язык не установлен, то устанавливаем русский
	if (!language) {
		setLanguage('ru')
	}

	useEffect(() => {
		// Запрашиваем данные аккаунта
		const getAccountData = async () => {
			try {
				const result = await api.accountApi.getInfo(); // Запрашиваем данные аккаунта
				setAuthorized(result.authorized) // Устанавливаем данные в authorized
				dispatch({type: "SET_USER_DATA", userData: result})

				if(result.authorized === true){
					if(topLevelDomain !== 'd' && result.entity?.country?.toLowerCase() !== topLevelDomain){
						window.location.href = `https://cabinet.apicore.${result.entity.country.toLowerCase()}/`
					}
				}

			} catch (error) {
				console.log(error) // Выводим ошибку в консоль
			} finally {
				setTimeout(() => {
					setIsLoading(false); // Устанавливаем isLoading в false после завершения запроса, независимо от того, успешен ли он или нет
				}, [700])
			}
		}
		getAccountData()
	}, []);

	const modals = useSelector(state => state.multiModal.modals)

	// Закрытие модального окна по нажатию на Escape
	const handleKeyDown = (event) => {

		if (event.key === 'Escape') {
			// Проходимся по массиву c конца , и если модальное окно открыто , то закрываем его и выходим с цикла
			// Метод find() находит первый открытый модал.
			const openModal = modals.slice().reverse().find(modal => modal.modalIsOpen === true);

			if (openModal) {
				dispatch({ type: "CLOSE_MODAL", modalLevel: openModal.modalLevel });
				dispatch({type: "CLEAR_UPLOADED_IMAGES"})
			}

			dispatch({type: "SWITCH_POPUP", popupType: null, popupIsOpen: false, currentCatalogItem: null})
		}
	};

	useEffect(() => {
		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [modals]);

	return (
		<div className={'i_cabinet'}>
			<Loader show={isLoading}/>
			<MultiModal/>
			<MultiHelp />
			<MultiPopup />
			<Overlay/>

			{authorized && (
				<Home/>
			)}

			{!authorized && (
				<>
					<Index/>
				</>
			)}
			<TabBar />
		</div>
	);
}

export default App;


