const initialState = {
	categoryList: null,
	productList: null,
	selectedProduct: [],
	selectedCategory: [],
	currentCategoryId: 0,
	catalogCode: null,
	nextOffset: 0
}

export const markupsReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_MARKUPS_CATEGORY':
			return {
				...state,
				categoryList: action.categoryList.map(item => {
					if(state.selectedCategory.includes(item.id)) {
						item.flag_select = true
					}
					return item
				}),
			}
		case 'SET_MARKUPS_PRODUCT':
			return {
				...state,
				productList: action.productList.map(item => {
					if(state.selectedProduct.includes(item.id)) {
						item.flag_select = true
					}
					return item
				}),
				nextOffset: action.nextOffset
			}
		case "GET_MARKUPS_MORE_PRODUCT_LIST":
			const newProductList = action.productList.map(item => {
				if(state.selectedProduct.includes(item.id)) {
					item.flag_select = true
				}
				return item
			})
			return {
				...state,
				productList: [...state.productList, ...newProductList],
				offset: action.offset,
				nextOffset: action.nextOffset
			};
		case "SET_NEXT_OFFSET" : {
			return {
				...state,
				nextOffset: action.nextOffset
			}
		}
		case 'SET_MARKUPS_SELECTED_PRODUCT':
			return {
				...state,
				selectedProduct: action.selectedProduct
			}
		case 'SET_MARKUPS_SELECTED_CATEGORY':
			return {
				...state,
				selectedCategory: action.selectedCategory
			}
		case 'SET_MARKUPS_CURRENT_CATEGORY_ID':{
			return {
				...state,
				currentCategoryId: action.currentCategoryId
			}
		}
		case 'SET_MARKUPS_CATALOG_CODE':{
			return {
				...state,
				catalogCode: action.catalogCode
			}
		}
		case 'CLEAR_MARKUPS_STATE':
			return initialState
		default:
			return state;
	}
}