import {ReactSVG} from "react-svg";
import deleteSvg from '../../../images/catalogs/category/delete.svg'
import {useDispatch, useSelector} from "react-redux";

const DeleteElement = () => {

	const dispatch = useDispatch();
	const currentCategoryId = useSelector(state => state.catalog.categoryList.currentCategoryId)

	return(
		<div className={`i_element-tools-item ${!currentCategoryId ? 'disabled' : ''}`}
		     onMouseEnter={() => dispatch({type: "SWITCH_CURSOR_HELP", show: true, content: 'Удалить элемент'})}
		     onMouseLeave={() => dispatch({type: "SWITCH_CURSOR_HELP", show: false})}
		     onClick={() => dispatch({type:'SWITCH_POPUP', popupType: 'deleteCatalogCategory', popupIsOpen: true})}
		>
			<ReactSVG src={`${deleteSvg}`} className={'i_element-tools-item-svg'}/>
		</div>
	)
}

export default DeleteElement;